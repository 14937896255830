@import "../../stylesheets/modules/modules";

.main-menu{
  color: $color-white;
  margin: 0 auto to-rem(30px);
  padding: to-rem(15px) 0 0;

  .container{
    @include phone{
      display: grid;
      grid-template-columns: 1fr;
      padding: 0;
    }
  }

  @include phone{
  }

  &__left{
    float: left;

    @include phone{
      width: 100%;
    }

    ul{
      display: block;
      list-style: none;
      margin: 0;
      overflow: auto;
      padding: 0;

      @include phone{
        display: flex;
        flex-direction: row;
      }

      li{
        display: inline-block;
        float: left;
        margin: 0 to-rem(50px) 0 0;

        @include phone{
          flex: 1;
          margin: 0;
        }

        a{
          border-bottom: to-rem(5px) solid transparent;
          float: left;
          padding: 15px 0;
          font-weight: $font-semibold;

          @include phone{
            padding: 5px 0;
            text-align: center;
            width: 100%;
          }

          &.active{
            border-bottom-color: $color-white;
          }

          svg{
            float: left;
            height: 20px;
            margin-right: to-rem(15px);

            @include phone{
              display: block;
              margin: 0;
              height: 15px;
              width: 100%;
            }
          }
          .nav-text{
            display: inline-block;
            float: left;
            font-size: to-rem(15px);
            line-height: to-rem(20px);

            @include phone{
              display: block;
              font-size: to-rem(12px);
              margin-top: to-rem(5px);
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__right{
    float: right;

    @include phone{
      display: none;
    }

    .button{
      float: left;
      margin: 0;

      @include phone{
        background: none;
        border-bottom: to-rem(5px) solid transparent;
        border-radius: 0;
        color: $color-white;
        padding: 15px 0;
        width: 100%;


        &.active{
          border-bottom-color: $color-white;
        }
      }

      .icon{
        @include phone{
          display: block;
          width: 100%;
        }
      }

      svg{
        float: left;
        height: 20px;

        @include phone{
          fill: $color-white !important;
          float: none;
          width: 100%;
        }
      }

      .nav-text{
        @include phone{
          display: block;
          font-size: to-rem(12px);
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}
