@import "../../stylesheets/modules/modules";

.repayment-card{
  display: grid;
  grid-template-columns: 7fr 5fr;
  position: relative;

  @include phone{
    grid-template-columns: 1fr;
  }

  @include tablet{
    grid-template-columns: 6fr 3fr;
  }

  @include phone{
    grid-template-columns: 1fr;
  }

  .left{
    padding: 0 to-rem(30px) 0 0;

    @include phone{
      padding: 0 0 to-rem(20px);
    }
  }

  .right{
    border: 0 solid rgba(165, 173, 182, 0.5);
    border-left-width: 1px;
    padding: 0 0 0 to-rem(30px);

    @include phone{
      border-left-width: 0;
      border-top-width: 1px;
      padding: to-rem(30px) 0 0;
    }
  }

  h6{
    font-size: to-rem(12px);
    margin: 0 0 to-rem(20px);

    @include phone-tablet{
      margin: 0 0 to-rem(15px);
    }
  }

  h1{
    line-height: 1em;
    margin: 0 0 to-rem(15px);
    padding: 0;

    @include phone-tablet{
      font-size: to-rem(30px);
      margin: 0 0 to-rem(10px);
    }

    span{
      color: $text-color-2;
      font-weight: $font-regular;
      margin: 0 to-rem(10px);

      @include phone{
        display: block;
        margin: 0;
      }
    }
  }

  button,
  .button{
    border: 1px solid;
  }
  .close-button{
    border:none
  }

  .repayment-progress{
    border-radius: to-rem(5px);
    height: to-rem(10px);
    margin: 0 0 to-rem(15px);
    overflow: hidden;
    width: 100%;

    .progress{
      border-radius: to-rem(5px);
      display: block;
      height: to-rem(10px);
      width: 0;
    }
  }

  .text{
    color: $text-color-2;
    display: block;
    font-weight: $font-semibold;
    margin: 0 0 to-rem(15px);

    @include phone-tablet{
      margin: 0 0 to-rem(10px);
    }
  }

  .overlay{
    background: rgba($color-white, 0.75);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    &-status{
      background: $color-white;
      border: 1px solid;
      border-radius: 12px;
      box-shadow: 2px 11px 22px rgba(0, 0, 0, 0.06);
      display: inline-block;
      font-size: 28px;
      left: 50%;
      line-height: 30px;
      margin: 0 auto;
      padding: to-rem(15px) to-rem(60px);
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.quick-repay{
  display: flex;
  margin: 0 -10px;

  @include phone-tablet {
    display: block;
    margin: 0;
  }

  .button {
    flex: 1;
    margin: 0 10px;

    @include phone-tablet {
      margin: 0 0 15px;
      padding: to-rem(10px);
      width: 100%;
    }
  }
}
