@import '../../stylesheets/modules/modules';

.notification {
  display: flex;
  justify-content: center;
  align-items: center;

  &_holder {
    background: $color-white;
    border-radius: 12px;
    box-shadow: 2px 11px 22px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
    max-width: 100%;
    padding: to-rem(80px) to-rem(90px) to-rem(110px);
    width: 600px;
  }

  &_title {
    font-weight: $font-semibold;
    letter-spacing: 0.1px;
    line-height: to-rem(26px);
    text-align: center;
  }

  &_icon-holder {
    margin: to-rem(25px) 0;
    text-align: center;
  }

  &_text {
    font-size: to-rem(24px);
    font-weight: $font-semibold;
    line-height: to-rem(24px);
    margin-bottom: to-rem(13px);
    text-align: center;
  }

  @include phone {
    &_holder {
      left: 16%;
      width: 100%;
      padding: to-rem(30px);
    }
  }
}
