@import "../../stylesheets/modules/modules";

.data-card{
	background: #FFFFFF;
	border-radius: to-rem(12px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 0 0 to-rem(32px);
	padding: to-rem(40px) to-rem(32px);
	position: relative;
	transition: 0.3s;

	@include phone {
		padding: to-rem(25px);
	}

	&.no-pad{
		padding: 0;
	}

	&__title{
		margin: 0 0 to-rem(24px);
	}

	&.loading{
		&:before{
			background: rgba($color-white, 0.75);
			border-radius: to-rem(12px);
			cursor: wait;
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			z-index: 9;
			width: 100%;
		}

		&:after{
			animation: spin .75s linear infinite;
			border: 7.5px solid transparent;
			border-radius: 50%;
			box-sizing: border-box;
			content: "";
			display: inline-block;
			float: left;
			height: to-rem(75px);
			left: 0;
			margin: auto;
			top: calc(50% - 37.5px);
			position: absolute;
			right: 0;
			width: to-rem(75px);
			z-index: 99;
		}
	}


	@keyframes spin {
		to {
			-webkit-transform: rotate(1turn);
			transform: rotate(1turn)
		}
	}
}
