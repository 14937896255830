@import "../../../stylesheets/modules/modules";

textarea{
	background: no-repeat top 10px right 10px;
	border: 1px solid $text-color;
	border-radius: to-rem(4px);
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	height: auto;
	line-height: to-rem(24px);
	margin: 0 auto;
	outline: none;
	padding: to-rem(12px) to-rem(16px);
	resize: none;
	width: 100%;

	@include placeholder-style($text-color)
}

.textarea{
	@extend textarea;

	&.border-bottom{
		border-radius: 0;
		border-width: 0 0 1px 0;
		line-height: to-rem(20px);
		padding: 0 0 to-rem(15px);
	}

	&.error{
		background-image: url("../../../static/icons/error.svg");
		border-color: $color-system-fail;
	}

	&__error{
		color: $color-system-fail;
		display: block;
		font-size: to-rem(11px);
		margin: 0;

		&:empty{
			display: none;
		}
	}
 
	&__label{
	  color: $text-color-2;
	  display: inline-block;
	  float: left;
	  font-size: to-rem(11px);
	  font-weight: $font-semibold;
	  line-height: to-rem(16px);
	  overflow: hidden;
	  margin: 0 0 to-rem(8px);
	  text-transform: uppercase;
	}

	&__group{
		display: block;
		margin: 0 0 to-rem(20px);
	}

	&__more-info{
		display: block;
		font-size: to-rem(12px);
		line-height: to-rem(16px);
		margin-top: to-rem(8px);
	}
}
