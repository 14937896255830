@import "../../stylesheets/modules/modules";

.verify-bvn-banner {
  .alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    gap: 20px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      width: 100%;
    }

    .button {
      color: $color_red;
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
