@import "../../stylesheets/modules/modules";

.button{
	border: none;
	border-radius: to-rem(4px);
	box-shadow: none;
	color: $color-white;
	cursor: pointer;
	display: inline-block;
	font-size: to-rem(16px);
	font-weight: $font-semibold;
	line-height: to-rem(20px);
	outline: none;
	//overflow: hidden;
	padding: to-rem(10px) to-rem(32px);
	position: relative;
	text-align: center;
	transition: 0.3s;

	&:after{
		border: 2px solid transparent;
		border-radius: 50%;
		border-right-color: $color-white;
		box-sizing: border-box;
		content: "";
		display: inline-block;
		float: right;
		left: 0;
		right: 0;
		margin: 0 auto;
		height: to-rem(20px);
		position: absolute;
		visibility: hidden;
		width: to-rem(20px);
	}

	&:active,
	&:focus,
	&:hover{
		color: $color-white;
	}

	&:disabled,
	&.disabled {
	  cursor: not-allowed;

		&:hover {
			.button-tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&-text{
		display: inline-block;
		transition: 0.3s;
	}

	&.small{
		font-size: to-rem(14px);
		padding: to-rem(5px) to-rem(15px);
	}

	&.secondary{
		background: $color-white;
		border: 1px solid;

		&:disabled{
			.icon{
				svg{
					fill: $disabled;
				}
			}
		}
	}

	&-tooltip {
		background: $color-white;
		border-radius: 5px;
		box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
		font-size: to-rem(13px);
		font-weight: $font-regular;
		left: 0;
		line-height: 1.25em;
		opacity: 0;
		padding: 10px;
		position: absolute;
		bottom: calc(100% + 5px);
		transition: 0.3s;
		visibility: hidden;
	}

	&-link {
		background: none;
		border-radius: 0;
		color: $text-color;
		padding: 0;
		margin:  to-rem(10px) to-rem(32px);
		transition: 0.3s;

		&:hover,
		&:focus{
			color: $text-color;
		}
	}

	&.previous{
		background: $color-white;
		border: 1px solid transparent;
	}

	&.next{
		border: 1px solid transparent;
	}

	&.facebook{
		background: $facebook-blue;
		border-color: $facebook-blue;

		.icon{
			float: left;

			svg{
				display: block;
			}
		}
	}

	.icon{
		svg{
			fill: $color-white;
		}
	}

	&.icon-left{
		.icon{
			margin-right: to-rem(12px);
		}
	}

	&.icon-right{
		.icon{
			float: right;
			margin-left: to-rem(12px);
		}
	}

	&.right{
		float: right;
	}

	&.block{
		display: block;
		width: 100%;
	}

	&.center{
		display: block;
		margin: 0 auto;
	}

	&.center-inline{
		display: inline-block;
		margin: 0 auto;
	}

	&.loading{

		.button-text{
			opacity: 0;
		}

		&:after{
			animation: spin .6s linear infinite;
			visibility: visible;
		}
	}

	&-list{
		button{
			margin-right: to-rem(20px);
		}
	}

	&-prev-next{
		margin: to-rem(30px) 0 0;
		overflow: hidden;
		text-align: center;

		.button{
			margin: 0 auto to-rem(15px);

			&:last-of-type{
				margin: 0 auto;
			}
		}

		&:empty{
			display: none;
		}
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}
