@import "../../stylesheets/modules/modules";

.layout {
  position: relative;

  &.authenticated {
    header.header {
      background: $color-white;
    }

    main.main {
      align-items: center;
      min-height: calc(100vh - 27rem);

      @include phone {
        min-height: calc(100vh - 15rem);
      }
    }
  }

  main.main {
    align-items: center;
    min-height: calc(100vh - 16rem);

    @include phone {
      min-height: calc(100vh - 15rem);
    }
  }
}

.not-found {
  align-items: center;
  display: flex;
  margin: 0 auto;
  padding: to-rem(75px) to-rem(15px);
  position: relative;
  text-align: center;
  width: 100%;

  .data-card {
    margin: 0 auto;
    padding: to-rem(50px) 30px;
    max-width: to-rem(600px);
    width: 100%;

    &__title {
      margin: 0 0 to-rem(30px);
    }

    h1 {
      font-size: to-rem(75px);
      margin: 0 0 to-rem(30px);
    }
    .button{
      color: rgb(76, 92, 109);
      &:hover{
       text-decoration: underline;
      }
    }
  }
}

.error-layouts {
  background: #f4f5f6;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  gap: 30px;
  padding: 20px;
  justify-content: center;
  width: 100vw;

  ::-moz-selection {
    color: #ffffff;
    background: #0077ff;
    opacity: 1;
    text-shadow: none;
  }

  ::selection {
    color: #ffffff;
    background: #0077ff;
    opacity: 1;
    text-shadow: none;
  }

  .error-wrapper {
    align-items: center;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 600px;
    padding: 48px;
    text-align: center;
    width: 100%;

    .error-message {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      max-width: 432px;
      margin: 0 auto;
    }

    p {
      color: #697275;
      font-size: 15px;
      font-weight: 600;
      max-width: 432px;
      margin: 0 auto 10px;

      a {
        color: #0077ff;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .use-originate-button {
      background: #fbfbfb;
      border: 1px solid #0077ff;
      border-radius: 4px;
      box-shadow: none;
      color: #0077ff;
      cursor: pointer;
      display: inline-block;
      font-weight: 600;
      line-height: 2rem;
      padding: 1rem 2.5rem;
      transition: 0.3s;
      outline: none;
      overflow-anchor: none;
    }
  }
}
