@import '../../stylesheets/modules/modules';

.sign-in {
  align-items: center;
  display: flex;
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  padding: to-rem(75px) to-rem(15px);
  position: relative;
  width: 100%;

  .data-card {
    margin: 0 auto;
    max-width: 100rem;
    padding: to-rem(70px);
    width: 100%;

    @include phone {
      padding: to-rem(64px) to-rem(32px);
    }

    &__title {
      font-size: to-rem(28px);
      text-align: center;
      line-height: 120%;
      margin-top: -3rem;
    }

    &__content {
      margin: 0 auto;
    }

    .form-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @include phone {
        flex-direction: column;
        padding: 10px;
      }


      .form-container {
        width: 48%;

        form {
          margin-top: 20px;
        }
        @include phone {
            width: 100%;
            text-align: center;
        }
  
      }

      .title {
        font-size: to-rem(20px);
        text-align: left;
        line-height: 80%;
        font-weight: 700;
        @include phone {
          text-align: center;
        }
  
      }

      .signup-container {
        max-width: 100%;
        width: 45%;
        line-height: to-rem(20px);
        display: flex;
        flex-direction: column;
        @include phone {
          width: 100%;
          align-items: center;
          padding: 10px;
        }
  

        .button {
          border: none;
          margin-top: 10px;
          border-radius: to-rem(4px);
          box-shadow: none;
          color: $color-white;
          cursor: pointer;
          display: inline-block;
          font-size: to-rem(16px);
          font-weight: $font-semibold;
          line-height: to-rem(20px);
          outline: none;
          padding: to-rem(10px) to-rem(32px);
          text-align: center;
          width: 40%;
        }
      }

      .divider {
        border: 1px solid #d6ddeb;
        @include phone {
          margin: 20px 0;
        }
  
      }
    }

    .create-account {
      display: block;
      margin: to-rem(25px) 0 0;
      text-align: center;

      a {
        font-weight: $font-semibold;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .button {
      min-width: 150px;
      margin-top: to-rem(40px);
    }
    
  }

  .center-text {
    text-align: center;
  }

  
}
