@import "../../stylesheets/modules/modules";

footer.footer{
  align-items: center;
  color: $color-white;
  display: flex;
  font-weight: $font-semibold;
  height: to-rem(105px);
  line-height: to-rem(25px);
  position: relative;
  @include phone{
    height: auto;
    padding: to-rem(30px) 0;
  }

  .container{
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include phone{
      display: block;
      text-align: center;
    }

    .footer__helpline{
      padding: 0;
      margin: 0;

      @include phone{
        margin: 0 0 20px;
      }

      span{
        margin-right: 10px;

        @include phone{
          display: block;
        }
      }

      li{
        display: inline-block;

        @include phone{
          display: block;
        }

        &:empty{
          &:after{
            content: none;
          }
        }

        &:after{
          content: "|";
          margin-left: 10px;
          margin-right: 10px;

          @include phone{
            display: none;
          }
        }

        &:last-of-type{
          &:after{
            content: none;
          }
        }
      }
    }
  }
}

