@import "../../stylesheets/modules/modules";

.sign-up-step {
	&__title, &__sub-title {
		margin: 0 0 to-rem(32px);
		text-align: center;
		line-height: 120%;

		&.small-title {
			font-size: to-rem(20px);
			font-weight: $font-regular;
		}
	}

	&__title {
		font-size: to-rem(28px);
	}

	&__sub-title {
		font-size: to-rem(16px);
	}

	&__content {
		overflow: auto;

		&.grid {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(to-rem(250px), 1fr));
			grid-gap: 30px;
		}

		.feedback-icon {
			margin: to-rem(30px) 0;
		}

		.tandc {
			display: flex;
			align-items: center;
			margin: 50px 0 0;
			overflow: auto;

			a {
				padding: 0 to-rem(5px);
				font-weight: 400;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.subtitle {
		font-weight: $font-regular;
		text-align: center;
	}

	aside.note {
		color: rgba($text-color-3, 0.5);
		font-size: to-rem(13px);
		font-weight: $font-semibold;
		margin: to-rem(24px) 0 0;
		text-align: center;
	}
	aside.help-note {
		color: rgba($text-color-3, 0.5);
		font-size: to-rem(11px);
		font-weight: $font-semibold;
		margin: to-rem(22px) 0 0;
	}

	#addCardStep {
		.form-group {
			margin: 0 0 to-rem(30px);
		}
	}

	.react-datepicker-wrapper {
		display: block;

		.react-datepicker__input-container {
			display: block;
		}
	}

	.checkbox-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.checkbox {
			border: 1px solid;
			padding: 10px;
			display: flex;
			justify-content: center;
			width: 100%;

			&__label {
				margin-left: 30px;
				height: auto;
				width: auto;

				&:before,
				&:after {
					margin-left: -30px;
				}
			}
		}
	}
}
.sign-up-step__footer {
	display: block;
	margin: to-rem(25px) 0 0;
	text-align: center;

	a {
		font-weight: $font-semibold;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
.message {
	color: $color-green;
	font-size: to-rem(14px);
	font-weight: $font-semibold;
	margin: 13px 0 0 0;
	text-align: center;
}
