@import "../../stylesheets/modules/modules";

.bread-crumbs{
    background: $color-white;
    border-radius: 4px;
    box-shadow: 2px 11px 22px rgba($color-black, 0.06);
    display: inline-block;
    font-weight: $font-semibold;
    margin: 0 0 to-rem(30px);
    padding: to-rem(8px) to-rem(15px);

    .bread-crumb{
        display: inline-block;

        &:after{
            content: url("../../static/icons/chevron-right.svg");
            display: inline-block;
            margin: 0 to-rem(15px);
        }

        &:last-of-type{
            margin: 0;

            &:after{
                content: none;
            }
        }

        a{
            color: $text-color-2;

            &:hover{
                text-decoration: underline;
            }

            svg{
                margin-bottom: -2px;
                width: 18px;

                path{
                    fill: $text-color-2;
                }
            }
        }
    }
}
