@import "../../stylesheets/modules/modules";

.loader {
	align-content: center;
	align-items: center;
	background-color: rgba($text-color, 0.7);
	display: flex;
	height: 100vh;
	justify-content: center;
	justify-items: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 999;

	&__content{
		position: relative;
		line-height: to-rem(50px);

		&:before{
			animation: spin .75s linear infinite;
			border: 7.5px solid rgba($text-color, 0.25);
			border-radius: 50%;
			border-right-color: $color-white;
			box-sizing: border-box;
			content: "";
			display: inline-block;
			float: left;
			left: 0;
			right: 0;
			height: to-rem(75px);
			width: to-rem(75px);
		}

		&-text{
			color: $color-white;
			float: left;
			font-weight: $font-semibold;
			margin: 0 0 0 20px;
		}
	}
}

@keyframes spin {
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}
