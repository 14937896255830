@import "../../stylesheets/modules/modules";

.repayment-breakdown{
  border: 1px solid;
  border-radius: 4px;
  font-size: to-rem(16px);
  margin: 0;
  padding: to-rem(25px);

  @include phone{
    font-size: 15px;
    padding: 15px;
  }

  li{
    font-weight: $font-semibold;
    list-style: none;
    line-height: to-rem(20px);
    margin: 0 0 to-rem(15px);

    @include phone{
      margin: 0 0 to-rem(15px);
    }

    span.value{
      float: right;

      @include phone{
        display: block;
        float: none;
      }
    }

    &:last-of-type{
      margin: 0;
    }
  }
}
