@font-face {
    font-family: 'Proxima Nova';
    src: url('../../static/fonts/proxima_nova/ProximaNova-Medium.woff2') format('woff2'),
        url('../../static/fonts/proxima_nova/ProximaNova-Medium.woff') format('woff'),
        url('../../static/fonts/proxima_nova/ProximaNova-Medium.ttf') format('truetype'),
        url('../../static/fonts/proxima_nova/ProximaNova-Medium.svg#ProximaNova-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../static/fonts/proxima_nova/ProximaNova-Semibold.woff2') format('woff2'),
        url('../../static/fonts/proxima_nova/ProximaNova-Semibold.woff') format('woff'),
        url('../../static/fonts/proxima_nova/ProximaNova-Semibold.ttf') format('truetype'),
        url('../../static/fonts/proxima_nova/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../static/fonts/proxima_nova/ProximaNova-Light.woff2') format('woff2'),
        url('../../static/fonts/proxima_nova/ProximaNova-Light.woff') format('woff'),
        url('../../static/fonts/proxima_nova/ProximaNova-Light.ttf') format('truetype'),
        url('../../static/fonts/proxima_nova/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../static/fonts/proxima_nova/ProximaNova-Bold.woff2') format('woff2'),
        url('../../static/fonts/proxima_nova/ProximaNova-Bold.woff') format('woff'),
        url('../../static/fonts/proxima_nova/ProximaNova-Bold.ttf') format('truetype'),
        url('../../static/fonts/proxima_nova/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../static/fonts/proxima_nova/ProximaNova-Regular.woff2') format('woff2'),
        url('../../static/fonts/proxima_nova/ProximaNova-Regular.woff') format('woff'),
        url('../../static/fonts/proxima_nova/ProximaNova-Regular.ttf') format('truetype'),
        url('../../static/fonts/proxima_nova/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

