@import "../../stylesheets/modules/modules";

.new-loan{
  max-width: to-rem(850px);

  .data-card{
    padding-bottom: to-rem(60px);
    padding-top: to-rem(60px);

    @include phone{
      padding: to-rem(15px);
    }

    &__content{
      margin: 0 auto;
      max-width: to-rem(530px);
    }

    h2{
      line-height: 1em;
      margin: 0 0 to-rem(20px);
    }

    h5{
      font-size: to-rem(20px);
      font-weight: $font-regular;
      line-height: to-rem(26px);
      margin: 0 auto to-rem(50px);
      max-width: to-rem(270px);
    }
  }

  &-accordion{
    .rc-collapse {
      background-color: #f7f7f7;
      border-radius: 3px;
      border: 1px solid #d9d9d9;

      &-item{
        border: 1px solid rgba($border-color, 0.25);
        border-radius: 12px;
        margin: 0 0 to-rem(20px);

        h6{
          color: $text-color-2;
          display: block;
          font-size: to-rem(11px);
          font-weight: $font-semibold;
          line-height: to-rem(16px);
          overflow: hidden;
          margin: 0 0 to-rem(15px);
          text-transform: uppercase;
        }

        .radio-group{
          margin-bottom: to-rem(40px);

          &:last-child{
            margin-bottom: 0;
          }

          .radio{
            &:only-child{
              margin: 0;
            }
          }

          &.three-grid-column{
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
          }
        }

        .card-repayment,
        .disbursement-bank{
          .radio{
            text-transform: capitalize;
            width: 100%;
          }
        }
      }

      &-anim-active {
        transition: height 0.2s ease-out;
      }

      &-header{
        box-sizing: border-box;
        color: $text-color-2;
        cursor: pointer;
        font-weight: $font-semibold;
        line-height: to-rem(30px);
        outline: none;
        padding: to-rem(13px) to-rem(15px);
        text-transform: uppercase;

        @include phone{
          padding: to-rem(7.5px) to-rem(10px);
        }

        &:before{
          border: solid;
          border-width: 2px 2px 0 0;
          box-sizing: border-box;
          display: inline-block;
          content: "";
          height: to-rem(10px);
          margin: 0 to-rem(16px) 0 to-rem(5px);
          transform: rotate(45deg);
          transition: 0.3s;
          width: to-rem(10px);
        }

        &.confirmed{
          &:after{
            border: 1px solid transparent;
            border-radius: 4px;
            content: "confirmed";
            float: right;
            font-size: to-rem(12px);
            line-height: 18px;
            padding: 5px 10px;

            @include phone{
              font-size: to-rem(10px);
              margin-top: 5px;
              padding: 0 3px;
            }
          }
        }

        &[aria-expanded='false']{
          transition: 0.3s;
        }

        &[aria-expanded='true']{

          &:before{
            transform: rotate(135deg);
          }
        }
      }

      &-content{
        padding: to-rem(20px) to-rem(50px) to-rem(30px);

        @include phone{
          padding: to-rem(10px) to-rem(15px) to-rem(15px);
        }

        &-inactive {
          display: none;
        }
      }
    }
  }

  .loan-radio {
    display: block;

    .loan_product {
      padding: to-rem(6px) to-rem(12px);
      border: 1px solid $color-grey;
      border-radius: 6px;
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      margin: to-rem(2px) 0 to-rem(10px) 0;

      &_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-weight: 700;
        }
      }

      &_description {
        padding-bottom: 5px;
        li {
          list-style: none;
        }
      }

      .radio__label {
        padding: 2rem 1rem;
      }

      .radio {
        span {
          border: none;
        }
      }
    }
  }
}

.newLoan-Tandc {
  display: flex;

  a {
    margin: 0 3px;
  }
}
