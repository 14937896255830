@import "../../stylesheets/modules/modules";
.button-link {
    border-bottom: none;
    background: none !important;
    color: $color-status-rejected;
    margin-left: 0px;
    font-size: 18px;

    :hover {
        text-decoration: underline;
    }
}
