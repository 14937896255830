@import "../../stylesheets/modules/modules";

.bank-card{
  background: linear-gradient(219.38deg, rgba(240, 247, 255, 0) 21.46%, #F0F7FF 77.46%);
  border: to-rem(1px) solid #D2D6DB;
  border-radius: to-rem(12px);
  box-shadow: to-rem(2px) to-rem(11px) to-rem(22px) rgba(0, 0, 0, 0.06);
  color: $color-black;
  display: block;
  height: to-rem(130px);
  margin: 0 0 20px;
  max-width: 100%;
  padding: to-rem(20px);
  width: to-rem(345px);

  @include phone{
    width: 100%;
  }

  &__top{
    margin-bottom: to-rem(18px);
    .card-status {
      display: inline;
    }

    .icon {
      display: inline;
      float: right;
    }
  }

  .radio{
    margin: 0;

    input{
      &:checked + span {
        background: none;
        border-color: transparent;
        box-shadow: none;
        color: transparent;

        &:before {
          background: none;
          border: to-rem(2px) solid $color-white;
          box-sizing: border-box;
        }

        &:after{
          background: url("../../static/icons/check-icon.svg") center no-repeat;
        }
      }
    }

    span {
      border: none;
      float: none;

      &:before {
        background: none;
        border: to-rem(2px) solid $color-white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        height: to-rem(25px);
        top: 0;
        width: to-rem(25px);
      }

      &:after{
        height: to-rem(21px);
        left: 12px;
        top: 2px;
        width: to-rem(21px);
      }
    }
  }
}
