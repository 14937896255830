@import "../../../stylesheets/modules/modules";

.checkbox{
  cursor: pointer;
  display: inline-block;

  input{
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;

    &:checked + .checkbox__label {
      background: $color-white;

      &:after {
        border-color: $color-white;
      }
    }

    &:disabled ~ span {
      cursor: not-allowed;
    }
  }

  &__label {
    display: block;
    font-weight: $font-semibold;
    float: left;
    line-height: to-rem(20px);
    height: to-rem(20px);
    outline: none;
    position: relative;
    transition: all 0.2s ease-in;
    width: 100%;

    &:before {
      border: 1px solid;
      box-sizing: border-box;
      content: "";
      float: left;
      height: 20px;
      left: 0;
      margin-right: 10px;
      position: relative;
      top: 0;
      width: 20px;
    }

    &:after {
      border-left: 2px solid transparent;
      border-bottom: 2px solid transparent;
      content: "";
      height: 3px;
      left: 5px;
      position: absolute;
      top: 6px;
      transform: rotate(-45deg);
      width: 8px;
    }
  }
}
