@import "../../stylesheets/modules/modules";

.header{
  align-items: center;
  background: $color-white;
  padding: to-rem(15px) 0;
  position: relative;
  z-index: 1;

  @include phone{
    padding: to-rem(10px) 0;

    .button{
      margin: to-rem(2.5px) 0;
      padding: to-rem(7.5px) to-rem(15px);
    }
  }

  .container{

  }

  &__left{
    float: left;
    overflow: auto;
  }

  &__right{
    float: right;
    overflow: auto;
  }

  a{
    float: left;
    min-width: to-rem(150px);

    img{
      float: left;
      height: 40px;
    }

    svg{
      float: left;
      margin: 4px 0;
    }
  }
}
