@import "../../../stylesheets/modules/modules";

input{
	background: $color-white center right to-rem(16px) no-repeat;
	border: 1px solid $text-color;
	border-radius: to-rem(4px);
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: to-rem(20px);
	margin: 0 auto;
	outline: none;
	padding: to-rem(10px) to-rem(16px);
	transition: 0.3s;
	width: 100%;

	@include placeholder-style(rgba($text-color, 0.5));

	&:read-only{
		cursor: not-allowed;
	}
}

.input{
	@extend input;

	&-holder{
		display: flex;
		width: 100%;
	}

	&.border-bottom{
		border-radius: 0;
		border-width: 0 0 1px 0;
		background: $color-white center right 0 no-repeat;
		line-height: to-rem(20px);
		padding: to-rem(10px) 0;
	}

	&.margin-bottom{
		margin: 0 0 to-rem(30px);
	}

	&.margin-top{
		margin: to-rem(30px) 0 0 !important;
	}

	&.error{
		background-image: url("../../../static/icons/error.svg");
		border-color: $color-system-fail;
	}

	&__error{
		color: $color-system-fail;
		display: block;
		font-size: to-rem(11px);
		margin: 0;

		&:empty{
			display: none;
		}
	}

	&__help-text{
		display: block;
		font-size: to-rem(11px);
		margin: 0;

		&:empty{
			display: none;
		}
	}

	&__label{
		color: $text-color-2;
		display: inline-block;
		float: left;
		font-size: to-rem(11px);
		font-weight: $font-semibold;
		line-height: to-rem(16px);
		overflow: hidden;
		margin: 0 0 to-rem(8px);
		text-transform: uppercase;
	}

	&__group{
		display: block;
		margin: 0 0 to-rem(20px);

		&.has-append{
			position: relative;

			.append{
				border-radius: to-rem(4px);
				color: $text-color-3;
				font-weight: $font-semibold;
				left: 0;
				line-height: to-rem(32px);
				margin: to-rem(4px) 0;
				padding: 0 to-rem(10px);
				position: absolute;
			}

			input{
				padding-left: to-rem(60px);
			}
		}
	}

	&__more-info{
		display: block;
		font-size: to-rem(12px);
		line-height: to-rem(16px);
		margin-top: to-rem(8px);
	}

	&__link{
		float: right;
		font-size: to-rem(12px);
		line-height: to-rem(16px);

		a{
			display: block;
			outline: none;
			text-decoration: underline;

			&:hover{
				text-decoration: underline;
			}
		}
	}
}
