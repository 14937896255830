@import '../../stylesheets/modules/modules';

.offer-letter {
  .lead-text {
    line-height: 1.75;
  }

  .document-box {
    margin: 0 auto;
  }

  .button {
    &-link {
      color: inherit;
      margin: 0;
      padding: 0;

      span {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.close-popup {
      background: none;
      height: to-rem(30px);
      padding: 0;
      position: absolute;
      right: to-rem(15px);
      top: to-rem(15px);
      width: to-rem(20px);
    }

    @include phone {
      width: 100%;
    }
  }

  .response-icon-holder {
    margin: 0 auto to-rem(20px);
    text-align: center;
  }

  p:last-child {
    margin: 0;
  }

  &-popup {
    .popup__content {
      max-width: to-rem(550px);

      .otpsign-form {
        max-width: to-rem(275px);
        margin: 0 auto to-rem(30px);

        .input__group {
          margin-bottom: 1rem;

          input {
            letter-spacing: to-rem(10px);
            font-size: to-rem(20px);
            line-height: to-rem(20px);
            text-align: center;
  
            &:-moz-placeholder {
              font-size: to-rem(14px);
              letter-spacing: 1px;
            }
  
            &::-moz-placeholder {
              font-size: to-rem(14px);
              letter-spacing: 1px;
            }
  
            &:-ms-input-placeholder {
              font-size: to-rem(14px);
              letter-spacing: 1px;
            }
  
            &::-webkit-input-placeholder {
              font-size: to-rem(14px);
              letter-spacing: 1px;
            }
          }
        }

        .button {
          width: 100%;

          &.resend-otp {
            display: flex;
            margin: 0 0 2rem auto;
            width: auto;
          }
        }
      }

      .otpsign-footer p {
        font-size: to-rem(13px);
        text-align: center;

        .button {
          font-size: to-rem(13px);
          font-weight: $font-regular;
        }
      }
    }
  }

  &-summary {
    border: 1px dashed rgba(201, 206, 217, 0.95);
    box-sizing: border-box;
    border-radius: 3px;
    font-size: to-rem(14px);
    font-weight: $font-semibold;
    margin: 0 auto to-rem(20px);
    max-width: to-rem(370px);
    padding: to-rem(20px);

    &__item {
      display: flex;
      margin-bottom: to-rem(5px);

      span {
        display: inline-block;
        opacity: 0.7;

        + span {
          margin-left: auto;
          opacity: 1;
        }
      }
    }
  }
}

p.small-text {
  font-size: 12px;
}

.decline-offer {
  display: flex;
  justify-content: flex-end;

  @include phone {
    display: block;
    margin: 0 auto;
    max-width: 320px;
  }

  .decline_button {
    order: 1;
  }
}
