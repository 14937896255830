@import "../../stylesheets/modules/modules";

.transaction-table{
  font-size: to-rem(14px);
  list-style-type: none;
  margin: 0;
  padding: 0;

  .alert{
    margin: 30px 0 0;
  }

  li{
    align-items: center;
    border-bottom: 1px solid $disabled;
    display: grid;
    font-weight: $font-semibold;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    justify-content: space-between;
    padding: to-rem(20px) 0;
    width: 100%;


    @include phone{
      grid-template-columns: 1fr;
      margin: 0 0 to-rem(15px);
      padding: 0 0 to-rem(15px);

      &:last-of-type{
        border-bottom: none;
        margin: 0;
        padding: 0;
      }
    }

    .tx-column{
      position: relative;

      @include phone{
        line-height: to-rem(24px);
        margin: 0 0 to-rem(5px);
        padding: 0 0 0 50%;
        text-align: right;

        &.full{
          margin: 0;
          padding: 0;
        }
      }

      &::before {

        @include phone{
          color: $text-color-2;
          content: attr(data-header);
          display: inline-block;
          font-size: to-rem(12px);
          left: 0;
          position: absolute;
          text-align: left;
          width: 50%;
        }
      }

      .button{
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &:first-of-type{
      padding: 0;
    }
  }

  .transaction-table__header{
    border: none;
    color: $text-color-2;
    font-size: to-rem(12px);
    font-weight: $font-semibold;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @include phone{
      display: none;
    }
  }

  .transaction-date{
    color: $text-color-2;

    @include phone{
      color: $text-color;
    }
  }
}
