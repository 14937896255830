@import "../../stylesheets/modules/modules";

.debit-card{
  border-radius: to-rem(12px);
  box-shadow: to-rem(2px) to-rem(11px) to-rem(22px) rgba(0, 0, 0, 0.06);
  color: $color-white;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: to-rem(186px);
  margin: 0 0 20px;
  max-width: 100%;
  overflow: hidden;
  padding: to-rem(20px);
  position: relative;
  width: to-rem(345px)!important;

  &:after{
    background: linear-gradient(219.38deg, #FFFFFF 43.4%, rgba(255, 255, 255, 0) 72.46%);
    border-radius: 100%;
    content: "";
    height: to-rem(250px);
    opacity: 0.1;
    position: absolute;
    width: to-rem(250px);
    right: -20%;
    top: -10%;
    z-index: 0;
  }

  @include phone{
    width: 100%;
  }

  &__pan{
    align-items: center;
    display: flex;
    font-size: to-rem(20px);
    font-weight: $font-semibold;
    justify-content: space-between;
    line-height: to-rem(24px);
    z-index: 1;

    &-mask{
      background: rgba($color-white, 0.5);
      height: 8px;
      width: 8px;
      border-radius: 4px;
      box-shadow: 15px 0 0 rgba($color-white, 0.5),
      30px 0 0 rgba($color-white, 0.5),
      45px 0 0 rgba($color-white, 0.5);
    }
  }

  &__top,
  &__bottom{
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  &__status {
    width: 100%;
  }
  &__expiry{
    color: rgba($color-white, 0.6);
    font-size: to-rem(15px);
    font-weight: $font-semibold;

    em{
      color: $color-white;
      font-style: normal;
      margin-left: 5px;
    }
  }

  .radio{
    margin: 0;

    input{
      &:checked + span {
        background: none;
        border-color: transparent;
        box-shadow: none;
        color: $color-white;

        &:before {
          background: none;
          border: to-rem(2px) solid $color-white;
          box-sizing: border-box;
        }

        &:after{
          background: url("../../static/icons/check-icon.svg") center no-repeat;
        }
      }
    }

    span {
      border: none;
      float: none;
      color: transparent;
      font-size: to-rem(13px);
      line-height: to-rem(27px);
      padding: 0 0 0 to-rem(35px);
      text-transform: uppercase;

      &:before {
        background: none;
        border: to-rem(2px) solid $color-white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        height: to-rem(25px);
        left: 0;
        top: 0;
        width: to-rem(25px);
      }

      &:after{
        height: to-rem(21px);
        left: 2px;
        top: 2px;
        width: to-rem(21px);
      }
    }
  }
}
