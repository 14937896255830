@import "../../stylesheets/modules/modules";

.recova-banner {
  .alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    gap: 20px;

    .button {
      color: $color_red;
    }
  }
}
