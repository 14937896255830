@import "../../../stylesheets/modules/modules";

.radio{
  cursor: pointer;
  display: inline-block;
  margin: 0 to-rem(15px) to-rem(20px) 0;

  input{
    margin: 0;
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;

    &:checked + span {
      background: $color-white;

      &:after {
        background: $color-white;
      }
    }
  }

  span {
    border: 1px solid $text-color;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-weight: $font-semibold;
    float: left;
    line-height: to-rem(20px);
    margin: 0;
    outline: none;
    padding: to-rem(10px) to-rem(10px) to-rem(10px) to-rem(40px);
    position: relative;
    transition: all 0.2s ease-in;
    width: 100%;

    &:before {
      background: transparent;
      border: 1px solid $text-color;
      border-radius: 100%;
      box-sizing: border-box;
      box-shadow: none;
      content: "";
      left: 10px;
      top: 10px;
      height: 20px;
      position: absolute;
      width: 20px;
    }

    &:after {
      border-radius: 100%;
      box-sizing: border-box;
      content: "";
      height: 8px;
      left: 16px;
      position: absolute;
      top: 16px;
      width: 8px;
    }
  }
  &--disabled{
    span{
      cursor: not-allowed;
      opacity: 0.65;
    }
  }

  &--noBorder{
    span {
      border: none;
      margin-top: to-rem(-10px);
    }
  }
}
