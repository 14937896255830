@import "../../stylesheets/modules/modules";

.card{
  display: grid;
  grid-gap: to-rem(50px);
  grid-template-columns: to-rem(300px) 1fr;
  margin: 0 0 to-rem(50px);

  @include phone{
    grid-template-columns: 1fr;
  }

  .card-title{
    font-weight: $font-semibold;
    margin: 0 0 to-rem(5px);
  }

  .card-subtitle{
    color: $text-color-2;
    font-weight: $font-regular;
    margin: 0 0;
  }

  .card-content{
    max-width: 500px;
  }
}