@import "../../stylesheets/modules/modules";

table,
.table{
  width: 100%;

  thead{
    tr{
      th{
        color: $text-color-2;
        font-size: to-rem(12px);
        line-height: 30px;
        letter-spacing: 0.05em;
        padding: to-rem(15px);
        text-align: left;
        white-space: nowrap;

        &:first-of-type{
          padding-left: to-rem(25px);
        }

        &:last-of-type{
          padding-right: to-rem(25px);
        }
      }
    }
  }

  tbody{
    tr{

      td{
        border-bottom: 1px solid $disabled;
        font-size: to-rem(14px);
        font-weight: $font-semibold;
        line-height: 30px;
        padding: to-rem(15px);

        &:first-of-type{
          padding-left: to-rem(25px);
        }

        &:last-of-type{
          padding-right: to-rem(25px);
        }
      }
    }
  }

  button,
  .button{
    padding: to-rem(6px) to-rem(25px);
    border: 1px solid transparent;
    border-radius: to-rem(4px);

    @include phone{
      padding: to-rem(2px) to-rem(10px);
    }
  }

  .first-td{
    color: $text-color-2;
  }

}
