@import "../../../stylesheets/modules/modules";

.radio-group {
  display: flex;
  flex-wrap: wrap;

  &.center{
    justify-content: center;

    .radio{

    }
  }

  &.two-grid-column{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: to-rem(15px);

    .radio{
      margin: 0;
    }
  }

  &.three-grid-column{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: to-rem(15px);

    .radio{
      margin: 0;
    }
  }
}
