@import '../../stylesheets/modules/modules';

.offline-repayment {
  max-width: to-rem(850px);

  .data-card {
    padding-bottom: to-rem(60px);
    padding-top: to-rem(60px);

    @include phone {
      padding: to-rem(15px);
    }

    &__content {
      margin: 0 auto;
      max-width: to-rem(600px);
    }

    h2 {
      line-height: 1em;
      margin: 0 0 to-rem(20px);
    }

    h5 {
      font-size: to-rem(20px);
      font-weight: $font-regular;
      line-height: to-rem(26px);
      margin: 0 auto to-rem(50px);
      max-width: to-rem(270px);
    }
  }

  &-account {
    border: 1px solid transparent;
    border-radius: 5px;
    margin: 0 0 to-rem(30px);
    padding: to-rem(20px);

    p {
      font-size: 1.5rem;
      margin: 0 0 to-rem(10px);
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 1.5rem;
        line-height: 2em;
        list-style: none;

        span {
          font-weight: $font-semibold;
        }
      }
    }
  }

  .document-upload {
    margin: 0 0 to-rem(25px);
    max-width: none;
    width: 100%;
  }
}
