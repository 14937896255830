//  COLORS  //

$tertiary-color: #002c5b;
$color-white: #ffffff;
$color-black: #000000;
$border-color: #4c5c6d;
$color-grey: #a4a59a;
$color-system-fail: #ae4036;
$color-system-success: #219653;
$color-system-pending: #2d9cdb;
$color-status-success: #6fcf97;
$color-status-rejected: #eb5757;
$color-primary: #fbfdff;
$text-color: #4c5c6d;
$text-color-2: #a5adb6;
$text-color-3: #002c5b;
$disabled: #d3d4d8;
$color-green: $color-system-success;
$color-red: #b35151;
$color-yellow: #cc9e58;
$color-background-grey: #e5e5e5;
$facebook-blue: #3b5998;
$document-background: rgba(157, 216, 88, 0.12);
$document-title: #0077ff;

//  FONT WEIGHT  //
$font-regular: 400;
$font-semibold: 600;

//  FONT FAMILY  //
$default-font: 'Proxima Nova', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
  'Roboto', 'Helvetica', 'Arial', 'sans-serif', 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';

//  BREAKPOINTS  //
$mobile-width: 767px;
$tablet-width: 979px;
$medium-width: 1199px;
