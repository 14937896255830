@import "../../stylesheets/modules/modules";

.popup{
  height: 100vh;
  left: 0;
  padding: 0 15px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;

  &__content{
    border-radius: 1.2rem;
    margin: 0 auto;
    max-height: 90vh;
    max-width: to-rem(600px);
    overflow: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &.center-title {
    .data-card{
      margin: 0;
      padding: to-rem(50px);

      @include phone{
        padding: to-rem(20px);
      }

      &__title{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }

  &.loan-requirements,
  &.offer-letter-popup {
    .data-card{
      margin: 0;
      padding: to-rem(50px);

      @include phone{
        padding: to-rem(20px);
      }

      &__title{
        margin-left: auto;
        margin-right: auto;
        max-width: to-rem(450px);
        text-align: center;
      }

      &__content{
        margin-left: auto;
        margin-right: auto;
        max-width: to-rem(450px);

        .consent-message{
          p{
            font-size: 11px;
            line-height: 1.75em;
            text-align: justify;
          }
        }
      }

      p{
        font-size: to-rem(18px);
        margin: 0 0 to-rem(30px);
      }

      ul{
        margin: 0 0 to-rem(25px);
        padding: 0;

        li{
          line-height: to-rem(25px);
          list-style: none;
          margin: 0 0 to-rem(15px);
          padding-left: to-rem(40px);

          &.small{
            font-size: to-rem(10px);
          }

          @include phone{
            margin: 0 0 to-rem(10px);
          }

          &:before{
            background: url("../../static/icons/checklist.svg") center left no-repeat;
            border-radius: 100%;
            color: $color-white;
            content: "";
            display: inline-block;
            float: left;
            font-size: to-rem(15px);
            font-weight: $font-semibold;
            height: to-rem(25px);
            line-height: to-rem(33px);
            margin-left: to-rem(-40px);
            margin-right: to-rem(10px);
            text-align: center;
            width: to-rem(25px);
          }
        }
      }
    }

    .button-list{
      display: grid;
      grid-template-columns: 1fr;
      text-align: center;

      .button{
        margin: 0 auto;
        max-width: 300px;
        width: 100%;
      }

      .link{
        color: rgba($text-color, 0.5);
        margin: to-rem(15px) auto;
      }
    }
  }

  &:before{
    background: rgba($color-black, 0.4);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &.edit-phone{
    .data-card{
      margin: 0;
      padding: to-rem(50px);

      @include phone{
        padding: to-rem(20px);
      }

      &__title{
        margin-left: auto;
        margin-right: auto;
        max-width: to-rem(450px);
        text-align: center;
      }
      
    }
  }

}