@import "../../stylesheets/modules/modules";

  .badge {
    background: $text-color;
    border-radius: to-rem(4px);
    color: $color-white;
    display: inline-block;
    font-size: to-rem(12px);
    line-height: to-rem(24px);
    padding: to-rem(5px); 
    width: 80%;
    text-align: center;
    text-transform: capitalize;

      &.green,
      &.active,
      &.success,
      &.approved,
      &.disbursed,
      &.confirmed,
      &.successful{
        background: $color-green;
      }
      
      &.paid{
        background: $text-color;
      }
      
      &.red,
      &.denied,
      &.failed,
      &.failure,
      &.declined,
      &.unresolved,
      &.unconfirmed{
        background: $color-red;
      }
      
      &.yellow,
      &.pending,
      &.on-hold,
      &.waiting,
      &.review,
      &.under_review,
      &.disbursing,
      &.in-progress{
        background: $color-yellow;
      }
  }
  
  .badge .tooltip {
    visibility: hidden;
    width: 230px;
    color: $text-color;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -30%; 
    opacity: 0;
    transition: opacity 0.3s;
    background: #FFFFFF;
    border: 1px solid #D6DFEC;
    box-sizing: border-box;
    border-radius: 5px;

  }
  
  .badge .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #D6DFEC transparent transparent transparent;
  }
  
  .badge:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }