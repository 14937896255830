@import '../../stylesheets/modules/modules';

.loan-details-card {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 5fr 6fr;
  position: relative;

  @include phone {
    grid-template-columns: 1fr;
  }

  &__left {
    h5 {
      text-transform: capitalize;
    }

    .status {
      background: $color-yellow;
      border-radius: 3px;
      color: $color-white;
      margin-left: 5px;
      font-size: 14px;
      padding: to-rem(5px) to-rem(10px);

      &.closed {
        background: $color-green;
      }

      &.denied {
        background: $color-red;
      }
    }
  }

  .repayment-breakdown {
    .button.secondary {
      border: 1px solid;
    }

    .quick-repay {
      margin-top: to-rem(20px);
    }

    h3 {
      small {
        font-weight: $font-regular;
        font-size: 100%;
        margin: 0 10px;
      }
    }

    .reason-button {
      width: 50%;

      a {
        text-decoration: underline;
      }
    }

    .close-icon {
      cursor: pointer;
      margin: to-rem(35px) to-rem(45px);
      position: absolute;
      right: 0;
      stroke: $text-color;
      top: 0;
    }
  }
}
