@import "../../stylesheets/modules/modules";

.repayment{
  margin-top: to-rem(30px);

  .repayment__title{
    font-weight: $font-semibold;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .repayment__text{
    color: $text-color-2;
    font-size: 16px;
    font-weight: $font-semibold;
    line-height: 28px;
  }

  .repayment__progress{
    background: rgba($color-green, 0.2);
    border-radius: to-rem(7px);
    height: to-rem(5px);
    max-width: to-rem(675px);
    width: 100%;
  }

  .vr {
    border-right: 1px solid rgba(165, 173, 182, 0.5);
    height: to-rem(190px);
  }
}

.visible-mobile{
  display: none !important;

  @include phone{
    display: flex !important;
    justify-content: center;
    margin: 0 auto 20px;
    max-width: 400px;

    .icon{
      height: to-rem(20px);

      svg{
        height: to-rem(20px);
      }
    }
  }
}

.alert .button.resend-vericode{
  background: none;
  border-bottom: 1px solid #B35151;
  border-radius: 0;
  color: inherit;
  margin: 0 0 0 10px;
  padding: 0;
  text-decoration: underline;
  transition: 0.3s;

  &:hover{
    border-bottom-color: transparent;
  }
}

.mandate {
  .alert{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    
    .button{
      color: $color_red;
    }
  }
}

