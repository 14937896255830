@import '../../stylesheets/modules/modules';

.supporting_documents {
  .popup_text {
    text-align: center;
    font-size: 14px;
    line-height: 28px;
  }

  .upload_documents {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: -10px;

    .document-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed $border-color;
      width: 48%;
      height: 190px;
      background: $document-background;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 10px 0;

      .input__group {
        margin: 0;

        input {
          display: none;
        }
      }

      .document {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;

        .document-detail {
          display: inline-block;
          font-weight: $font-semibold;
          margin-top: 10px;
          max-width: 275px;
          overflow-wrap: break-word;
        }
      }
    }
  }

  @include phone {
    .upload_documents {
      flex-direction: column;
      .document-upload {
        width: 70%;
        height: 150px;
      }
    }
  }
}

.loan_adjustment {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .popup_text {
    font-size: 14px;
    line-height: 28px;
  }

  .loan_text {
    text-transform: uppercase;
    font-size: 12px;
    color: $text-color-2;
    margin-bottom: 10px;
  }

  .loan_amount {
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .adjustment_link {
    margin-top: -10px;

    .button-link {
      border-bottom: none;

      .link {
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
        border-bottom: none;
      }

      .link:hover {
        text-decoration: underline;
      }
    }
  }
}

.decision_buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 38px;
  width: 100%;

  @include phone {
    justify-content: space-between;
    width: 80%;
    margin: 10px auto;
    flex-flow: column-reverse wrap;
  }

  .button {
    font-size: 12px;
    font-weight: bold;
    height: 45px;
    width: 185px;

    @include phone {
      width: 100%;
      margin-bottom: 10px
    }
  }

  .decline_button {
    background-color: transparent;
    border: 1px solid;
    margin-right: 100px;
  }
}

.breakdown-popup {
  .data-card__title {
    display: flex;
    justify-content: center;
  }

  .repaymentBreakdown {
    border-left: 2px solid;
    border-right: 2px solid;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    .middleAlign {
      text-align: center;
    }

    .rightAlign {
      text-align: right;
    }

    .leftAlign {
      text-align: left;
    }

    div {
      display: inline-block;
      padding: 5px 8px;
      width: 25%;
    }

    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li:nth-of-type(even) {
      background-color: $color-white;
    }

    &__header {
      border-top: 2px solid;
      border-bottom: 2px solid;
      font-size: 12px;
      font-weight: bold;
      width: 100%;
    }

    &__body {
      font-size: 12px;
      width: 100%;
    }
  }
}
