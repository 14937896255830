@import '../../stylesheets/modules/modules';

.document-box {
  background: #f8fafe;
  border: 1px dashed #d6dfec;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: to-rem(170px);
  justify-content: center;
  margin-bottom: to-rem(20px);
  text-align: center;
  width: to-rem(370px);

  .icon {
    display: inline-block;
    margin: 0 auto 10px;

    svg {
      display: block;
    }
  }

  .doc-name {
    font-size: 14px;
    margin: 0 auto 5px;
  }

  .doc-link {
    background: none;
    border: none;
    display: inline-block;
    font-size: 14px;
    font-weight: $font-semibold;
    line-height: 1.5;
    margin: 0 auto;
    text-decoration: underline;

    .button-text {
      cursor: pointer;
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
