@import "../../stylesheets/modules/modules";

.alert{
	border: 1px solid $color-grey;
	border-radius: to-rem(4px);
	display: block;
	font-size: to-rem(14px);
	line-height: to-rem(25px);
	margin: 0 0 to-rem(24px);
	padding: to-rem(15px) to-rem(20px);
	position: relative;
	text-align: center;
	width: 100%;

	&.small{
		font-size: to-rem(13px);
		padding: to-rem(5px) to-rem(10px);
	}

	.dismiss{
		background: none;
		border: none;
		box-shadow: none;
		cursor: pointer;
		margin: 0;
		outline: none;
		padding: 0;
		position: absolute;
		right: to-rem(12px);
		top: to-rem(12px);

		svg{
			display: block;
		}
	}

	&.error{
		border-color: $color-red;
		background-color: rgba($color-red, 0.07);
		color: $color-red;
	}

	&.warning{
		border-color: $color-red;
		background-color: rgba($color-red, 0.07);
		color: $color-red;
	}

	&.success{
		border-color: $color-green;
		background-color: rgba($color-green, 0.07);
		color: $text-color;
	}
}
