@import "../../../stylesheets/modules/modules";

select{
	appearance: none;
	background: $color-white url("../../../static/icons/caret-down.svg") center right to-rem(10px) no-repeat;
	border: 1px solid $text-color;
	border-radius: to-rem(4px);
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: to-rem(20px);
	outline: none;
	padding: to-rem(10px) to-rem(25px) to-rem(10px) to-rem(16px);
	width: 100%;
}

.select{
	@extend select;

	&.border-bottom{
		border-radius: 0;
		border-width: 0 0 1px 0;
		line-height: to-rem(20px);
		padding: to-rem(10px) 0;
	}

	&.margin-bottom{
		margin: 0 0 to-rem(30px);
	}

	&.margin-top{
		margin: to-rem(30px) 0 0 !important;
	}

	&.error{
		background-image: url("../../../static/icons/error.svg");
		border-color: $color-system-fail;
	}

	&__error{
		color: $color-system-fail;
		display: block;
		font-size: to-rem(11px);
		margin: 0;

		&:empty{
			display: none;
		}
	}

	&__label{
		color: $text-color-2;
		display: inline-block;
		float: left;
		font-size: to-rem(11px);
		font-weight: $font-semibold;
		line-height: to-rem(16px);
		overflow: hidden;
		margin: 0 0 to-rem(8px);
		text-transform: uppercase;
	}

	&__group{
		display: block;
		margin: 0 0 to-rem(20px);
	}

	&__more-info{
		display: block;
		font-size: to-rem(12px);
		line-height: to-rem(16px);
		margin-top: to-rem(8px);
	}
}
